<template>
  <div class="main">
    <span>我的课程</span>
    <div class="el">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="学习中" name="first">
          <template v-if="result.length > 0">
            <div v-for="item of result" :key="item.id" class="Course-item">
              <a href="#" class="Course-item-link">
                <img :src="item.courseLogo" alt="" class="Course-item-img" />
              </a>
              <div class="Course-item-info">
                <div class="Course-item-title">
                  <a href="#" class="cd-link-major">{{ item.courseName }}</a>
                </div>
                <div class="Course-item-progress">
                  <span class="Course-item-progress-text">学习进度</span>
                  <div class="cd-progress">
                    <!-- <div class="progress-bar">
                  <div class="progress-outer">
                    <div class="progress-inner" style="width:0%"></div>
                  </div>
                </div> -->
                    <!-- <div class="progress-text">0%</div> -->
                    <!-- 进度条 -->
                    <el-progress
                      :percentage="percentage"
                      color="#43bc60"
                    ></el-progress>
                  </div>
                </div>
              </div>
              <div class="Course-item-btn">
                <!-- <a href="#" class="cd-btn">继续学习</a> -->
                <el-button type="success" class="cd-btn">继续学习</el-button>
                <el-button type="warning" v-if="item.canBeReplaced" class="cd-btn">换课申请</el-button>
                <el-button v-if="item.exchangeStatus === 2" class="cd-btn">申请审核</el-button>
                <el-button v-if="item.exchangeStatus === 3" class="cd-btn">申请失败</el-button>
              </div>
            </div>
          </template>
          <div v-else class="cd-empty">暂无数据</div>
        </el-tab-pane>
        <el-tab-pane label="已学完" name="second">
          <template v-if="result2.length > 0">
            <div v-for="item of result2" :key="item.id" class="Course-item">
              <a href="#" class="Course-item-link">
                <img :src="item.courseLogo" alt="" class="Course-item-img" />
              </a>
              <div class="Course-item-info">
                <div class="Course-item-title">
                  {{ item.courseName }}
                </div>
                <div class="Course-item-progress">
                  <span class="Course-item-progress-text">学习进度</span>
                  <div class="cd-progress">
                    <!-- <div class="progress-bar">
                  <div class="progress-outer">
                    <div class="progress-inner" style="width:0%"></div>
                  </div>
                </div> -->
                    <!-- <div class="progress-text">0%</div> -->
                    <!-- 进度条 -->
                    <el-progress
                      :percentage="item.finishPercent"
                      color="#43bc60"
                    ></el-progress>
                  </div>
                </div>
              </div>
              <!-- <div class="Course-item-btn">
                <el-button
                  @click="goDetail(item.courseId)"
                  type="success"
                  class="cd-btn"
                  >继续学习</el-button
                >
                <el-button
                  v-if="item.canBeReplaced"
                  @click="handleApplyClass"
                  type="warning"
                  class="cd-btn"
                  >换课申请</el-button
                >
              </div> -->
            </div>
          </template>
          <div v-else class="cd-empty">暂无数据</div>
        </el-tab-pane>
        <el-tab-pane label="收藏" name="third">
          <template v-if="result3.length > 0">
            <div v-for="item of result3" :key="item.id" class="Course-item">
              <a href="#" class="Course-item-link">
                <img :src="item.courseLogo" alt="" class="Course-item-img" />
              </a>
              <div class="Course-item-info">
                <div class="Course-item-title">
                  {{ item.courseName }}
                </div>
                <!-- <div class="Course-item-progress">
                <span class="Course-item-progress-text">学习进度</span>
                <div class="cd-progress">
                  <el-progress
                    :percentage="item.finishPercent"
                    color="#43bc60"
                  ></el-progress>
                </div>
              </div> -->
              </div>
              <div class="Course-item-btn">
                <!-- <a href="#" class="cd-btn">继续学习</a> -->
                <el-button
                  @click="goDetail(item.courseId)"
                  type="success"
                  class="cd-btn"
                  >去学习</el-button
                >
                <!-- <el-button v-if="item.canBeReplaced" @click="handleApplyClass" type="warning" class="cd-btn">换课申请</el-button> -->
              </div>
            </div>
          </template>
          <div v-else class="cd-empty">暂无数据</div>
        </el-tab-pane>
      </el-tabs>
      <div class="pagination">
        <Pagination
          :total="search.total"
          :page.sync="search.page"
          :limit.sync="search.size"
          @pagination="getDataList"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getCourseList } from '@/api/common'
import { getCollectedCourse } from '@/api/myCenter'
import Pagination from '@/components/Pagination'
export default {
  name: 'Course',
  components: {
    Pagination
  },
  data() {
    return {
      search: {
        page: 1,
        size: 10,
        total: 0 //分页
      },
      activeName: 'first',
      percentage: 10, //进度条控制
      result: [], // 学习中数据
      result2: [], // 已学完数据
      result3: [] // 收藏数据
    }
  },
  mounted() {
    this.getDataList('first')
  },
  methods: {
    //获取课程分页数据
    getDataList(type) { //// type=first, second
      this.search.queryType = 2;
      getCourseList(Object.assign(this.search, { categoryId: 0 })).then(
        (res) => {
          if (res.data.code === 0) {
            this.search.total = res.data.data.total
            if(type === 'first') {
              this.result = res.data.data.rows
            }
            if(type === 'second') {
              this.result2 = res.data.data.rows
            }
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        }
      )
    },
    //获取收藏课程分页数据
    getCollectedList() {
      delete this.search.categoryId
      delete this.search.finishFlag
      getCollectedCourse(this.search).then((res) => {
        if (res.data.code === 0) {
          this.search.total = res.data.data.total
          this.result3 = res.data.data.rows
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    handleClick(tab) {
      console.log(tab.name)
      this.search.page = 1
      this.search.size = 10
      this.search.total = 0
      this.result = []
      if (tab.name === 'first') {
        this.getDataList('first',
          Object.assign(this.search, {
            finishFlag: false
          })
        )
      }
      if (tab.name === 'second') {
        this.getDataList('second',
          Object.assign(this.search, {
            finishFlag: true
          })
        )
      }
      if (tab.name === 'third') {
        this.getCollectedList()
      }
    },
    //继续学习
    goDetail(courseId) {
      this.$router.push({
        path: `/classdetail?id=${courseId}`
      })
    },
    // 换课申请
    handleApplyClass(id) {
      let courseId = JSON.stringify(id)
      sessionStorage.setItem('courseId', courseId)
      this.$router.push({
        path: '/'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  span {
    display: flex;
  }
  .el {
    border-top: 1px solid #f5f5f5;
    margin-top: 10px;
    .Course-item {
      padding: 8px 24px 125px 8px;
      margin-bottom: 10px !important;
      border: 2px solid #eee;
      height: 10px;
      background: url('../../../assets/play-bg.jpeg') left top repeat-y #fff !important;
      a {
        text-decoration: none;
      }
      .Course-item-link {
        position: relative;
        margin-right: 24px;
        float: left; 
        img {
          border: 0;
          vertical-align: middle;
         
        }
        .Course-item-img {
          width: 227px;
        }
      }
      .Course-item-info {
        float: left;
        margin-top: 8px;
        width: 45%;
        .Course-item-title {
          width: 45%;
          font-size: 20px;
          line-height: 28px;
          display: block;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-wrap: normal;
          .cd-link-major {
            color: rgba(0, 0, 0, 0.88);
          }
        }
        .Course-item-progress {
          color: rgba(0, 0, 0, 0.56);
          margin-top: 32px !important;
          .Course-item-progress-text {
            float: left;
            width: 20%;
          }
          .cd-progress {
            float: left;
            width: 80%;
            margin-bottom: 0;
            position: relative;
            .progress-bar {
              width: 96%;
              box-sizing: border-box;
              display: inline-block;
              padding-right: 40px;
              margin-right: -40px;
              background: none;
              box-shadow: none;
              float: left;
              height: 100%;
              font-size: 12px;
              color: #fff;
              text-align: center;
              .progress-outer {
                width: 96%;
                border-radius: 6px;
                display: inline-block;
                background-color: rgba(0, 0, 0, 0.08);
                .progress-inner {
                  height: 4px;
                }
              }
              .progress-text {
                width: 42px;
                font-size: 16px !important;
                color: #919191;
                display: inline-block;
                vertical-align: middle;
                margin-left: 4px;
                text-align: left;
                line-height: 1;
              }
            }
          }
        }
      }
      .Course-item-btn {
        margin-top: 48px;
        float: right;
        .cd-btn {
          color: #fff;
          /* background: #43bc60; */
          border-color: #43bc60;
          display: inline-block;
          text-align: center;
          vertical-align: middle;
          touch-action: manipulation;
          cursor: pointer;
          white-space: nowrap;
          user-select: none;
          font-size: 14px;
          font-weight: 500;
          padding: 9px 12px;
          line-height: 1;
          border: 1px solid transparent;
          border-radius: 4px;
          outline: none;
        }
      }
    }
  }
  .cd-empty {
    text-align: center;
    color: rgba(0, 0, 0, 0.32);
    padding: 20px 0;
  }
  .pagination {
    margin-top: 30px;
  }
}
</style>
